import React from "react";
import { Card } from "antd";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

import useScrollToTop from "../../utils/useScrollToTop";
import MainMenu from "../../components/MainMenu";

const Project = () => {
  useScrollToTop();

  return (
    <div
      style={{ display: "flex", flexDirection: "column", gap: 10, padding: 10 }}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Link to="/home">
          <img style={{ width: 22 }} alt="chat2course" src="/logo.png" />
        </Link>
        <MainMenu />
      </div>
      <Card style={{ border: 0, backgroundColor: "#c3e3ff", padding: 4 }}>
        <h2 style={{ fontFamily: "Outfit" }}>Contact</h2>
      </Card>
      <Card>
        <h2>Support</h2>
        <p>
          For assistance, bug reports, suggestions, refund requests, or any
          other inquiries, please email us at{" "}
          <a href="mailto:contact@reqres.dev">contact@reqres.dev</a>.
        </p>
      </Card>

      <Card>
        <h2>Let's Work Together</h2>
        <p>
          I'm open to business collaborations and new opportunities. Feel free
          to reach out to discuss potential partnerships.
        </p>
        <p>
          Looking for a developer? Check out my{" "}
          <a
            href="https://me.reqres.dev/"
            target="_blank"
            rel="noopener noreferrer"
          >
            portfolio
          </a>{" "}
          to see my work and experience.
        </p>
      </Card>
    </div>
  );
};

export default Project;
